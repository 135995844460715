import React from 'react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { useState } from 'react';
import LoadingIndicator from 'react-loading-indicator';
import { useEffect } from 'react';
import axios from 'axios';
import nochat from './message.png'
import { BiPencil } from 'react-icons/bi';
import History from './History';

export default function Messages(props) {
    const [error,setError] = useState("")
    const [questionsLoad,setQuestionsLoad] = useState(true)
    const [chatHeads,setChatHeads] = useState("")
    const [actChats,setActChats] = useState("")
    const [inactChats,setInactChats] = useState("")
    const [actTab,setActTab] = useState(1)
    const [messageTab,setMessageTab] = useState(true)
    const [chatId,setChatId] = useState("")
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            getChatHeads()
        }
        a++
    },[])
    const getChatHeads = () =>{
        axios.post(global.url + "api/user/getgroups",{
            userid : props.user.userid,
        },{
            headers : {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(props.newToken != undefined && props.newToken != null && props.newToken != "" ? props.user.newToken : defaultToken)
            }
        })
        .then((res) =>{
            setChatHeads(res.data)
            setActChats(res.data.active)
            setInactChats(res.data.inactive)
            setQuestionsLoad(false)
        },(error) =>{
            setError(error)
        })
    }
    const openChat = (x) =>{
        setChatId(x)
        setMessageTab(false)
    }
    const closeNotification = () =>{
        props.setChatTab(true)
        props.setChatOpen(false)
    }
  return (
    <>
    {messageTab ?
    <>
    <div className='cahttopsection container'>
        <div className='w90 float-center topsecboxcaht center-text'>
            <div className='container topchathead'>
                {/* {(props.chatNot) ?
                <></>
                :
                <p className='chatback' onClick={() => props.setTab()}><BsArrowLeft /> Back</p>
                } */}
                <p>Messages</p>
            </div>
        </div>
    </div>
    <div className='container chatlowerbox'>
        <div className="commonheadingchat container">
            <div className='chattabs container'>
                <div className='ctab'>
                    <div className={`ctabbox ${actTab == 1 ? "actchattab" : "" }`} onClick={() => setActTab(1)}>
                       <p>Active</p>
                    </div>
                    <div className={`ctabbox ${actTab == 2 ? "actchattab" : "" }`} onClick={() => setActTab(2)}>
                        <p>Inactive</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='chatheadbox container'>
            {(actTab == 1) ?
                actChats.length > 0 
                ?
                actChats.map((ques , i) =>{
                    let messageData = JSON.parse(ques.lastmsg)
                    return(
                    <div className='container cquestion' key={i}>
                        <div className='w95 float-center lastmessagechat' onClick={() => openChat(ques.chatid)}>
                            <div className='lastmsgicon'>
                                <img src={iconLogo} alt='' />
                            </div>
                            <div className='lastmsgtext'>
                                <div className='container lastmsgtop'>
                                    <p>Last Message</p>
                                    <p className='lastmsgdate'>{messageData.date}</p>
                                </div>
                                <div className='container lastmsgcht'>
                                    <p>{messageData.msg}</p>
                                </div>
                            </div>
                            {ques.userread !== 1 &&
                                <div className='newmsgdot'></div>
                            }
                        </div>
                    </div>
                )})
                :
                questionsLoad ? 
                <div className='questionsload container'>
                    <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                </div>
                :
                <div className='container noactchat'>
                    <div className='w90 float-center'>
                        <div className='noactchatimg'>
                            <img src={nochat} alt='' />
                        </div>
                        <div className='noactpara container center-text'>
                            <p>No Active Chats</p>
                        </div>
                        <div className='chatnowbutton container'>
                            <button onClick={() => openChat("")}> <BiPencil /> <p>Chat Now</p></button>
                        </div>
                    </div>
                </div>
                :
                inactChats.length > 0 
                ?
                inactChats.map((ques , i) =>{
                    let messageData = JSON.parse(ques.lastmsg)
                    return(
                    <div className='container cquestion' key={i}>
                        <div className='w95 float-center lastmessagechat' onClick={() => openChat(ques.chatid)}>
                            <div className='lastmsgicon'>
                                <img src={iconLogo} alt='' />
                            </div>
                            <div className='lastmsgtext'>
                                <div className='container lastmsgtop'>
                                    <p>Last Message</p>
                                    <p className='lastmsgdate'>{messageData.date}</p>
                                </div>
                                <div className='container lastmsgcht'>
                                    <p>{messageData.msg}</p>
                                </div>
                            </div>
                            {ques.userread !== 1 &&
                                <div className='newmsgdot'></div>
                            }
                        </div>
                    </div>
                )})
                :
                <div className='container noactchat'>
                    <div className='w90 float-center'>
                        <div className='noactchatimg'>
                            <img src={nochat} alt='' />
                        </div>
                        <div className='noactpara container center-text'>
                            <p>No Inactive Chats</p>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className='fxchatnow chatnowbutton'>
            <button className='New Chat' onClick={() => openChat("")}> <BiPencil /> </button>
        </div>
    </div>
    </>
    :
    <History user={props.user} path={props.path} pathString={props.pathString} chatId={chatId} getChatHeads={() => getChatHeads()} messageTab={setMessageTab}/>
    }
    </>
  )
}
